import { AppConfig, ColDefExt, ColumnDefPresetConfig, DataRow, FieldService } from '@davidhiu/ignite-ng';
import { cloneDeep } from 'lodash-es';
import moment from 'moment-mini-ts';

export const getColumnDefPresetList = (
  appConfig: AppConfig,
  fieldService: FieldService
): ColumnDefPresetConfig['presetList'] => {
  // Common column definitions for all resources. All resources must have these common columns.
  const common: ColDefExt[] = [
    {
      headerName: '',
      width: 37,
      pinned: 'left',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMovable: true,
      enablePivot: false,
      enableRowGroup: false,
      enableValue: false,
      suppressMenu: true,
      lockPosition: true,
      lockVisible: true,
    },
    { headerName: '* Id *', field: 'id', width: 85, filter: 'agNumberColumnFilter' },
    { headerName: 'Workgroup', field: 'workgroup', width: 150 },
    { headerName: 'Created By', field: 'created_by', width: 150 },
    { headerName: 'Updated By', field: 'updated_by', width: 150 },
    {
      headerName: 'Created At',
      field: 'created_at',
      width: 200,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateTimeFormat) : ''),
      datetimeFormat: appConfig.dateTimeFormat,
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      width: 200,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateTimeFormat) : ''),
      datetimeFormat: appConfig.dateTimeFormat,
    },
  ];

  // Common column definitions for all legacy resources. All resources must have these common columns.
  const commonLegacy: ColDefExt[] = [
    {
      headerName: '',
      width: 37,
      pinned: 'left',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMovable: true,
      enablePivot: false,
      enableRowGroup: false,
      enableValue: false,
      suppressMenu: true,
      lockPosition: true,
      lockVisible: true,
    },
    { headerName: 'Created By', field: 'created_by', width: 150 },
    { headerName: 'Modified By', field: 'modified_by', width: 150 },
    {
      headerName: 'Created',
      field: 'created',
      width: 200,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateTimeFormat) : ''),
      datetimeFormat: appConfig.dateTimeFormat,
    },
    {
      headerName: 'Last Updated',
      field: 'last_updated',
      width: 200,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateTimeFormat) : ''),
      datetimeFormat: appConfig.dateTimeFormat,
    },
  ];

  return [
    //#region Master Module
    {
      resourceUrl: '/master/component',
      columnDefs: (() => {
        const defs = cloneDeep(commonLegacy);
        defs.splice(
          1,
          0,
          { headerName: '* Component Id *', field: 'component_id', width: 150 },
          { headerName: '* Component Name *', field: 'component_name', width: 400 },
          { headerName: '* Label *', field: 'label', width: 200 },
          { headerName: '* Specific *', field: 'specific', width: 150 },
          {
            headerName: '* Current Price *',
            field: 'current_price',
            width: 150,
            cellStyle: { textAlign: 'right' },
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) =>
              params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
          },
          {
            headerName: '* Previous Price *',
            field: 'previous_price',
            width: 150,
            cellStyle: { textAlign: 'right' },
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) =>
              params.value ? params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null,
          },
          {
            headerName: '* Buying Date *',
            field: 'buying_date',
            width: 150,
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateFormat) : ''),
            datetimeFormat: appConfig.dateFormat,
          },
          {
            headerName: '* Previous Buying Date *',
            field: 'previous_buying_date',
            width: 150,
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateFormat) : ''),
            datetimeFormat: appConfig.dateFormat,
          }
        );
        return defs;
      })(),
    },
    {
      resourceUrl: '/master/equipment',
      columnDefs: (() => {
        const defs = cloneDeep(commonLegacy);
        defs.splice(
          1,
          0,
          { headerName: '* Equipment Name *', field: 'equipment_name', width: 150 },
          { headerName: '* Factory Brand *', field: 'factory_brand', width: 150 },
          { headerName: '* Brand Type *', field: 'brand_type', width: 300 },
          { headerName: '* No Of Tires *', field: 'no_of_tires', width: 130 },
          { headerName: '* No Of Spare Tires *', field: 'no_of_spare_tires', width: 135 },
          { headerName: '* Reg Number *', field: 'reg_number', width: 150 },
          { headerName: '* Condition *', field: 'condition', width: 150 },
          { headerName: '* Trade *', field: 'equipment_trade', width: 150 },
          { headerName: '* Driver *', field: 'driver', width: 120 },
          { headerName: '* UID RFID *', field: 'uid_rfid', width: 170 },
          { headerName: '* UID NFC *', field: 'uid_nfc', width: 170 }
        );
        return defs;
      })(),
    },
    {
      resourceUrl: '/master/purchasing',
      columnDefs: (() => {
        const defs = cloneDeep(commonLegacy);
        defs.splice(
          1,
          0,
          { headerName: '* Document Id *', field: 'document_id', width: 150 },
          { headerName: '* PO Document Id *', field: 'po_document_id', width: 150 },
          {
            headerName: '* Invoice Date *',
            field: 'invoice_date',
            width: 150,
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateFormat) : ''),
            datetimeFormat: appConfig.dateFormat,
          },
          { headerName: '* Invoice Number *', field: 'invoice_number', width: 200 },
          { headerName: '* Supplier Id *', field: 'supplier_id', width: 150 },
          { headerName: 'Supplier Name', field: 'supplier_name', width: 250 },
          { headerName: '* Unmanaged Supplier *', field: 'unmanaged_supplier', width: 250 },
          { headerName: '* Trade *', field: 'trade', width: 200 },
          { headerName: '* Origin *', field: 'origin', width: 150 },
          { headerName: '* Reason Id *', field: 'reason_id', width: 150 },
          { headerName: '* Target *', field: 'target', width: 150 },
          { headerName: '* Specific Equipment Id *', field: 'specific_equipment_id', width: 150 },
          { headerName: '* Specific Department *', field: 'specific_department', width: 150 }
        );
        return defs;
      })(),
    },
    // #endregion

    //#region Tire Module
    {
      resourceUrl: '/tire/tire',
      columnDefs: (() => {
        const defs = cloneDeep(common);
        defs.splice(
          2,
          0,
          { headerName: '* Doc No *', field: 'doc_no', width: 130 },
          { headerName: '* UID RFID *', field: 'uid_rfid', width: 170 },
          { headerName: '* UID NFC *', field: 'uid_nfc', width: 170 },
          { headerName: '* Serial No *', field: 'serial_no', width: 170 },
          { headerName: '* Mnf date *', field: 'mnf_date', width: 120 },
          {
            headerName: '* Status *',
            field: 'status',
            width: 150,
            valueGetter: (params) =>
              params.data?.status ? fieldService.getOptionDisplay('/tire/tire', 'status', params.data.status) : null,
          },
          {
            headerName: '* Category *',
            field: 'category',
            width: 150,
            valueGetter: (params) =>
              params.data?.category
                ? fieldService.getOptionDisplay('/tire/tire', 'category', params.data.category)
                : null,
          },
          { headerName: '* RTX Gen *', field: 'rtx_gen', width: 115 },
          {
            headerName: '* Brand *',
            field: 'brand',
            width: 150,
            valueGetter: (params) =>
              params.data?.brand ? fieldService.getOptionDisplay('/tire/tire', 'brand', params.data.brand) : null,
          },
          { headerName: '* Size *', field: 'size', width: 150 },
          { headerName: '* Component Id *', field: 'component_id', width: 150 },
          { headerName: '* Current Equipment Id *', field: 'current_equipment_id', width: 150 },
          { headerName: '* Previous Equipment Id *', field: 'previous_equipment_id', width: 150 },
          { headerName: 'First Movement - Party', field: 'movements.0.party', width: 200 },
          { headerName: '* Remark *', field: 'remark', width: 300 }
        );
        return defs;
      })(),
    },
    {
      resourceUrl: '/tire/movement',
      columnDefs: (() => {
        const defs = cloneDeep(common);
        defs.splice(
          2,
          0,
          { headerName: '* Doc No *', field: 'doc_no', width: 130 },
          {
            headerName: '* Status *',
            field: 'status',
            width: 120,
            valueGetter: (params) =>
              params.data?.status
                ? fieldService.getOptionDisplay('/tire/movement', 'status', params.data.status)
                : null,
          },
          {
            headerName: '* Type *',
            field: 'type',
            width: 230,
            valueGetter: (params) =>
              params.data?.type ? fieldService.getOptionDisplay('/tire/movement', 'type', params.data.type) : null,
          },
          {
            headerName: 'Paired ORTN | PRTXs',
            valueGetter: (params) =>
              params.data?.paired_ortn?.doc_no ||
              (params.data?.paired_prtxs as DataRow[]).map((item) => item.doc_no).join(', '),
            width: 180,
          },
          {
            headerName: 'Event At',
            field: 'event_at',
            width: 150,
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateFormat) : ''),
            datetimeFormat: appConfig.dateFormat,
          },
          { headerName: '* Party *', field: 'party', width: 200 },
          { headerName: '* Ref Doc *', field: 'ref_doc', width: 150 },
          {
            headerName: '* Discard Reason *',
            field: 'discard_reason',
            width: 160,
            valueGetter: (params) =>
              params.data?.discard_reason
                ? fieldService.getOptionDisplay('/tire/movement', 'discard_reason', params.data.discard_reason)
                : null,
          },
          { headerName: '* Purchasing Id *', field: 'purchasing_id', width: 150 },
          { headerName: '* Remark *', field: 'remark', width: 300 }
        );
        return defs;
      })(),
    },
    {
      resourceUrl: '/tire/replacement',
      columnDefs: (() => {
        const defs = cloneDeep(common);
        defs.splice(
          2,
          0,
          { headerName: '* Doc No *', field: 'doc_no', width: 130 },
          {
            headerName: '* Status *',
            field: 'status',
            width: 120,
            valueGetter: (params) =>
              params.data?.status
                ? fieldService.getOptionDisplay('/tire/replacement', 'status', params.data.status)
                : null,
          },
          {
            headerName: '* Reason *',
            field: 'reason',
            width: 120,
            valueGetter: (params) =>
              params.data?.reason
                ? fieldService.getOptionDisplay('/tire/replacement', 'reason', params.data.reason)
                : null,
          },
          {
            headerName: 'Event At',
            field: 'event_at',
            width: 150,
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => (params.value ? moment(params.value).format(appConfig.dateFormat) : ''),
            datetimeFormat: appConfig.dateFormat,
          },
          {
            headerName: '* Active *',
            field: 'active',
            width: 100,
            valueGetter: (params) =>
              params.data?.active === true ? 'TRUE' : params.data?.active === false ? 'FALSE' : null,
          },
          { headerName: '* Active Duration *', field: 'active_duration', width: 80 },
          { headerName: '* Equipment *', field: 'equipment_id', width: 130 },
          { headerName: '* Position *', field: 'position', width: 84 },
          { headerName: '* Truck Head *', field: 'truck_head_id', width: 130 },
          { headerName: 'Tire Off Doc No', field: 'tire_off.doc_no', width: 130 },
          { headerName: 'Tire Off UID RFID', field: 'tire_off.uid_rfid', width: 170 },
          { headerName: 'Tire Off UID NFC', field: 'tire_off.uid_nfc', width: 170 },
          { headerName: 'Tire Off Serial No', field: 'tire_off.serial_no', width: 170 },
          { headerName: 'Tire Off Mnf Date', field: 'tire_off.mnf_date', width: 130 },
          {
            headerName: 'Tire Off Off Status',
            width: 150,
            valueGetter: (params) =>
              // Always going to be TAL, so we use `id` for null check and hardcoded `TAL` for field value.
              params.data?.tire_off?.id ? fieldService.getOptionDisplay('/tire/tire', 'status', 'TAL') : null,
          },
          {
            headerName: 'Tire Off Status',
            field: 'tire_off.status',
            width: 150,
            valueGetter: (params) =>
              params.data?.tire_off?.status
                ? fieldService.getOptionDisplay('/tire/tire', 'status', params.data.tire_off.status)
                : null,
          },
          {
            headerName: 'Tire Off Category',
            field: 'tire_off.category',
            width: 150,
            valueGetter: (params) =>
              params.data?.tire_off?.category
                ? fieldService.getOptionDisplay('/tire/tire', 'category', params.data.tire_off.category)
                : null,
          },
          { headerName: 'Tire Off RTX Gen', field: 'tire_off.rtx_gen', width: 115 },
          {
            headerName: 'Tire Off Brand',
            field: 'tire_off.brand',
            width: 150,
            valueGetter: (params) =>
              params.data?.tire_off?.brand
                ? fieldService.getOptionDisplay('/tire/tire', 'brand', params.data.tire_off.brand)
                : null,
          },
          { headerName: 'Tire Off Size', field: 'tire_off.size', width: 150 },
          { headerName: 'Tire Off First Movement Party', field: 'tire_off.movements.0.party', width: 150 },
          { headerName: 'Tire On Doc No', field: 'tire_on.doc_no', width: 130 },
          { headerName: 'Tire On UID RFID', field: 'tire_on.uid_rfid', width: 170 },
          { headerName: 'Tire On UID NFC', field: 'tire_on.uid_nfc', width: 170 },
          { headerName: 'Tire On Serial No', field: 'tire_on.serial_no', width: 170 },
          { headerName: 'Tire On Mnf Date', field: 'tire_on.mnf_date', width: 130 },
          {
            headerName: '* Tire On On Status *',
            field: 'tire_on_status',
            width: 150,
            valueGetter: (params) =>
              params.data?.tire_on_status
                ? fieldService.getOptionDisplay('/tire/replacement', 'tire_on_status', params.data.tire_on_status)
                : null,
          },
          {
            headerName: 'Tire On Status',
            field: 'tire_on.status',
            width: 150,
            valueGetter: (params) =>
              params.data?.tire_on?.status
                ? fieldService.getOptionDisplay('/tire/tire', 'status', params.data.tire_on.status)
                : null,
          },
          {
            headerName: 'Tire On Category',
            field: 'tire_on.category',
            width: 150,
            valueGetter: (params) =>
              params.data?.tire_on?.category
                ? fieldService.getOptionDisplay('/tire/tire', 'category', params.data.tire_on.category)
                : null,
          },
          { headerName: 'Tire On RTX Gen', field: 'tire_on.rtx_gen', width: 115 },
          {
            headerName: 'Tire On Brand',
            field: 'tire_on.brand',
            width: 150,
            valueGetter: (params) =>
              params.data?.tire_on?.brand
                ? fieldService.getOptionDisplay('/tire/tire', 'brand', params.data.tire_on.brand)
                : null,
          },
          { headerName: 'Tire On Size', field: 'tire_on.size', width: 150 },
          { headerName: 'Tire On First Movement Party', field: 'tire_on.movements.0.party', width: 150 },
          { headerName: 'Prev Replacement On', field: 'prev_replacement_on.doc_no', width: 130 },
          { headerName: 'Next Replacement Off', field: 'next_replacement_off.doc_no', width: 130 },
          {
            headerName: '* Trade *',
            field: 'trade',
            width: 170,
            valueGetter: (params) =>
              params.data?.trade
                ? fieldService.getOptionDisplay('/tire/replacement', 'trade', params.data.trade)
                : null,
          },
          { headerName: '* PIC *', field: 'pic', width: 150 },
          { headerName: '* Remark *', field: 'remark', width: 300 }
        );
        return defs;
      })(),
    },
    // #endregion
  ];
};
