@if (userLoggedIn()) {
  <div fxLayout="column">
    <div class="greeting">{{ greeting() }}</div>

    @if (userIsNotGuest()) {
      <div fxLayout="column">
        <div fxLayout="row wrap" fxLayoutAlign="center stretch">
          @if (allowAccessMenu('menu.dashboard')) {
            <mat-card fxFlex class="menu-button" (click)="openDashboard()">
              <mat-card-title>
                <span class="menu-label">Dashboard</span>
              </mat-card-title>
            </mat-card>
          }
          @if (allowAccessMenu('menu.tire.audit')) {
            <mat-card fxFlex class="menu-button" (click)="openAudit()">
              <mat-card-title>
                <span class="menu-label">Audit</span>
              </mat-card-title>
            </mat-card>
          }
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center stretch">
          @if (allowAccessMenu('menu.tire.replacement')) {
            <mat-card fxFlex class="menu-button" (click)="openReplacement()">
              <mat-card-title>
                <span class="menu-label">Replacement</span>
              </mat-card-title>
            </mat-card>
          }
          @if (allowAccessMenu('menu.tire.movement')) {
            <mat-card fxFlex class="menu-button" (click)="openMovement()">
              <mat-card-title>
                <span class="menu-label">Movement</span>
              </mat-card-title>
            </mat-card>
          }
        </div>
      </div>
    }

    @if (quote) {
      <div class="quote" fxLayout="column" fxLayoutAlign="start end">
        <div class="quote-text">{{ quote.quote }}</div>
        <div class="quote-source">
          <em>{{ quote.source }}</em>
        </div>
      </div>
    }
  </div>
}
