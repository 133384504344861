import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { AuthGuardService } from '@davidhiu/ignite-ng';
import { HomeComponent } from './home/home.component';

const APP_ROUTE: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    resolve: {
      _: () => inject(AuthGuardService).userLoggedIn(),
    },
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./dashboard/dashboard.component'),
    canMatch: [() => inject(AuthGuardService).userLoggedIn()],
    canActivate: [(route: ActivatedRouteSnapshot) => inject(AuthGuardService).menuAccessGranted(route)],
  },
  {
    path: 'master',
    canMatch: [() => inject(AuthGuardService).userLoggedIn()],
    loadChildren: () => import('./master/master.route'),
  },
  {
    path: 'tire',
    canMatch: [() => inject(AuthGuardService).userLoggedIn()],
    loadChildren: () => import('./tire/tire.route'),
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

export default APP_ROUTE;
