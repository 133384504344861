import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { GLOBAL_STATE, HttpRestService, UserProfileService, UserType } from '@davidhiu/ignite-ng';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: true,
  imports: [AsyncPipe, FlexModule, MatCardModule],
})
export class HomeComponent {
  private _globalState = inject(GLOBAL_STATE);
  private _httpRestService = inject(HttpRestService);
  private _router = inject(Router);
  private _userProfileService = inject(UserProfileService);

  /**
   * A User is logged in flag.
   */
  userLoggedIn = this._globalState.signal('userLoggedIn');

  /**
   * Logged in user is not guest flag.
   */
  userIsNotGuest = this._globalState.computed(({ userType }) => userType() !== UserType.Guest);

  /**
   * Greeting text based on currently logged in user.
   */
  greeting = this._globalState.computed(({ userProfile }) => `Hello, ${userProfile().name}!`);

  /**
   * Quote.
   */
  quote?: { quote: string; source: string };

  /**
   * constructor.
   */
  constructor() {
    this._httpRestService
      .get<{ quote: string; source: string }>('/quote', undefined, false, true)
      .subscribe((result) => (this.quote = result));
  }

  /**
   * Check if card should be shown based on menu permission.
   */
  allowAccessMenu(permission: string) {
    return this._userProfileService.allowAccessMenu(permission);
  }

  /**
   * Open Dashboard component.
   */
  openDashboard() {
    this._router.navigate(['/dashboard']);
  }

  /**
   * Open Audit component.
   */
  openAudit() {
    this._router.navigate(['/tire/audit']);
  }

  /**
   * Open Replacement component.
   */
  openReplacement() {
    this._router.navigate(['/tire/replacement/create']);
  }

  /**
   * Open Movement component.
   */
  openMovement() {
    this._router.navigate(['/tire/movement/create']);
  }
}
