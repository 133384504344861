<mat-toolbar id="navbar" color="primary" class="mat-elevation-z6">
  @if (navigating()) {
    <mat-progress-bar mode="indeterminate" style="position: absolute"></mat-progress-bar>
  }

  <mat-toolbar-row>
    <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>

    <a id="app-name" mat-button routerLink="/">
      <mat-icon svgIcon="logo"></mat-icon>
      <span fxShow="false" fxShow.gt-xs>
        {{ appConfig.appName }} {{ appConfig.appEnvironment ? '(' + appConfig.appEnvironment + ')' : '' }}
      </span>
      <span fxHide="false" fxHide.gt-xs>
        {{ appConfig.appShortName }} {{ appConfig.appEnvironment ? '(' + appConfig.appEnvironment + ')' : '' }}
      </span>
    </a>

    <span fxFlex></span>

    @if (loggedIn()) {
      <button mat-button fxHide.lt-md="true" (click)="openProfile()" class="profile">
        {{ currentProfileLabel() }}
      </button>
    }

    <button mat-icon-button [matMenuTriggerFor]="moreMenu"><mat-icon>more_vert</mat-icon></button>

    <mat-menu #moreMenu="matMenu">
      <button mat-menu-item (click)="toggleDarkTheme()">
        <mat-icon>lightbulb_outline</mat-icon>
        <span>{{ toggleThemeLabel() }}</span>
      </button>
      <button mat-menu-item (click)="openHelp()">
        <mat-icon>help_outline</mat-icon>
        <span>Help</span>
      </button>
      <button mat-menu-item (click)="openAbout()">
        <mat-icon>info_outline</mat-icon>
        <span>About</span>
      </button>
      @if (loggedIn()) {
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      }
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container id="main-container">
  <mat-sidenav #sidenav id="sidenav" mode="over" position="start" autoFocus="a.active">
    @if (loggedIn()) {
      <mat-card id="profile" *rxLet="currentProfile$; let currentProfile">
        <button mat-icon-button color="accent" (click)="openProfile()">
          <mat-icon>settings</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title> {{ currentProfile.name }} </mat-card-title>
          <!-- prettier-ignore -->
          <mat-card-subtitle>
            <span>Email</span> <span>:</span> <span> {{ currentProfile.email }} </span>
            <span>Type</span> <span>:</span> <span> {{ currentProfile.type }} </span>
            <span>Roles</span> <span>:</span> <span> {{ currentProfile.roles }} </span>
            <span>Act Workgroup</span> <span>:</span> <span> {{ currentProfile.activeWorkgroup }} </span>
            <span>Act Storage</span> <span>:</span> <span> {{ currentProfile.activeStorage }} </span>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content></mat-card-content>
      </mat-card>
      <ign-menu></ign-menu>
    }
  </mat-sidenav>

  <ign-breadcrumbs [topOffset]="breadcrumbsTopOffset()" id="breadcrumbs"></ign-breadcrumbs>

  <div id="content">
    <router-outlet></router-outlet>
  </div>

  <mat-toolbar id="footer">
    <mat-toolbar-row id="browser-note">
      <span>
        Make sure you are using latest version of
        <a href="https://www.google.com/intl/en/chrome" target="blank">Chrome</a> or
        <a href="https://www.mozilla.org/en-US/firefox/new" target="blank">Firefox</a> to access the website.
      </span>
    </mat-toolbar-row>

    <mat-toolbar-row id="copyright" fxLayout="column" fxLayoutAlign="center center">
      <span [innerHtml]="appConfig.appCopyright" style="text-align: center"></span>
    </mat-toolbar-row>

    <div id="graffiti">
      <mat-icon svgIcon="logo"></mat-icon>
    </div>
  </mat-toolbar>

  <button id="back-to-top" mat-mini-fab (click)="backToTop()" class="tertiary-variant">
    <mat-icon>arrow_upward</mat-icon>
  </button>
</mat-sidenav-container>
