import { APP_INITIALIZER, enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { LicenseManager } from 'ag-grid-enterprise';

import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { rxState } from '@rx-angular/state';
import {
  ACTOR_BASE_PATH,
  APP_CONFIG,
  APP_CONFIG_DEFAULT,
  COLUMN_DEF_PRESET_CONFIG,
  ColumnDefPresetConfigDefault,
  FIELD_PRESET_CONFIG,
  FieldPresetConfigDefault,
  FieldService,
  GLOBAL_STATE,
  GLOBAL_STATE_DEFAULT,
  GlobalState,
  MENU_PRESET_CONFIG,
  MenuPresetConfigDefault,
  provideIgnite,
  QUERY_PRESET_CONFIG,
  QueryPresetConfigDefault,
  REPORT_PRESET_CONFIG,
  ReportPresetConfigDefault,
} from '@davidhiu/ignite-ng';
import { AppComponent } from './app/app.component';
import APP_ROUTE from './app/app.route';
import { getColumnDefPresetList } from './app/presets/column-def-preset-list';
import { getFieldPresetList } from './app/presets/field-preset-list';
import { getMenuPresetList } from './app/presets/menu-preset-list';
import { getQueryPresetList, getWhereHasList } from './app/presets/query-preset-list';
import { getReportPresetList } from './app/presets/report-preset-list';
import { environment } from './environments/environment';

if (environment.production || environment.staging) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    provideRouter(
      APP_ROUTE,
      withRouterConfig({
        // Allow user to refresh currently updated/read resource on crud component.
        onSameUrlNavigation: 'reload',
      })
    ),
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js', { enabled: environment.pwaEnabled }),
    provideIgnite(),
    {
      // Delay the app-root to show the logo spinner.
      provide: APP_INITIALIZER,
      useValue: () => new Promise((resolve) => setTimeout(resolve, 2500)),
      multi: true,
    },
    {
      provide: APP_CONFIG,
      useValue: {
        ...APP_CONFIG_DEFAULT,
        appId: 3,
        appName: 'Tire Management System',
        appShortName: 'TMS',
        appEnvironment: environment.development ? 'development' : environment.staging ? 'staging' : '',
        appBaseUrl: environment.appBaseUrl,
        appVersionUrl: environment.development ? '/version' : '/app/version',
        appChangelogUrl: environment.development ? '/CHANGELOG.md' : '/app/CHANGELOG.md',
        appCopyright:
          'Copyright © ' + new Date().getFullYear() + ' PT Jangkar Pasifik Transport' + '<br>All rights reserved',
        appDescription:
          'The Tire Management System (TMS) is made with ❤ and proudly presented to you by PT Jangkar Pasifik Transport.',
        authBaseUrl: environment.authBaseUrl,
        authLoginBaseUrl: environment.loginBaseUrl,
      },
    },
    {
      provide: GLOBAL_STATE,
      useFactory: () => {
        const state = rxState<GlobalState>();
        state.set({ ...GLOBAL_STATE_DEFAULT });
        return state;
      },
    },
    {
      provide: MENU_PRESET_CONFIG,
      useFactory: () => {
        const config = new MenuPresetConfigDefault();
        config.presetList = getMenuPresetList();
        return config;
      },
    },
    {
      provide: FIELD_PRESET_CONFIG,
      useFactory: () => {
        const config = new FieldPresetConfigDefault();
        config.presetList = getFieldPresetList();
        return config;
      },
    },
    {
      provide: COLUMN_DEF_PRESET_CONFIG,
      useFactory: () => {
        const config = new ColumnDefPresetConfigDefault();
        config.presetList = getColumnDefPresetList(inject(APP_CONFIG), inject(FieldService));
        return config;
      },
    },
    {
      provide: QUERY_PRESET_CONFIG,
      useFactory: () => {
        const config = new QueryPresetConfigDefault(getWhereHasList());
        config.presetList = getQueryPresetList(config.getWhereHasNodes.bind(config));
        return config;
      },
    },
    {
      provide: REPORT_PRESET_CONFIG,
      useFactory: () => {
        const config = new ReportPresetConfigDefault();
        config.presetList = getReportPresetList();
        return config;
      },
    },
    {
      provide: ACTOR_BASE_PATH,
      useValue: {
        tires: '/tire/tire',
        movements: '/tire/movement',
        replacements: '/tire/replacement',
      },
    },
  ],
}).catch((err) => console.error(err));

LicenseManager.setLicenseKey('J039F92UKN202RNK4H8H');
