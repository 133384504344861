import { ReportPresetConfig } from '@davidhiu/ignite-ng';

export const getReportPresetList = (): ReportPresetConfig['presetList'] => {
  return [
    //#region Master Module
    {
      resourceUrl: '/master/component',
      title: 'Component',
      reports: [{ name: 'List' }],
    },
    {
      resourceUrl: '/master/equipment',
      title: 'Equipment',
      reports: [{ name: 'List' }],
    },
    {
      resourceUrl: '/master/purchasing',
      title: 'Purchasing',
      reports: [{ name: 'List' }],
    },
    //#endregion

    //#region Tire Module
    {
      resourceUrl: '/tire/tire',
      title: 'Tire',
      reports: [{ name: 'List' }],
    },
    {
      resourceUrl: '/tire/movement',
      title: 'Movement',
      reports: [{ name: 'List' }],
    },
    {
      resourceUrl: '/tire/replacement',
      title: 'Replacement',
      reports: [{ name: 'List' }],
    },
    //#endregion
  ];
};
