import { Field, FieldPresetConfig, KeywordType } from '@davidhiu/ignite-ng';
import { cloneDeep } from 'lodash-es';

export const getFieldPresetList = (): FieldPresetConfig['presetList'] => {
  // Common fields.
  const common: Field[] = [
    { name: 'id', keywordType: KeywordType.Numeric, searchable: true },
    { name: 'created_by', keywordType: KeywordType.String, searchable: true },
    { name: 'updated_by', keywordType: KeywordType.String, searchable: true },
    { name: 'created_at', keywordType: KeywordType.Datetime, searchable: true },
    { name: 'updated_at', keywordType: KeywordType.Datetime, searchable: true },
  ];

  // Common fields for legacy resources.
  const commonLegacy: Field[] = [
    // Id field is added to avoid node setting error because it defaults to id field.
    { name: 'id', keywordType: KeywordType.Numeric, searchable: false },
    { name: 'created_by', keywordType: KeywordType.String, searchable: true },
    { name: 'modified_by', keywordType: KeywordType.String, searchable: true },
    { name: 'created', keywordType: KeywordType.Datetime, searchable: true },
    { name: 'last_updated', keywordType: KeywordType.Datetime, searchable: true },
  ];

  return [
    //#region Master Module
    {
      resourceUrl: '/master/component',
      fields: (() => {
        const fields = cloneDeep(commonLegacy);
        fields.splice(
          1,
          0,
          { name: 'component_id', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'component_name', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'label', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'specific', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'current_price', keywordType: KeywordType.Numeric, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'previous_price',
            keywordType: KeywordType.Numeric,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          { name: 'buying_date', keywordType: KeywordType.Datetime, searchable: true, fav: true },
          { name: 'previous_buying_date', keywordType: KeywordType.Datetime, searchable: true, fav: true }
        );
        return fields;
      })(),
    },
    {
      resourceUrl: '/master/equipment',
      fields: (() => {
        const fields = cloneDeep(commonLegacy);
        fields.splice(
          1,
          0,
          { name: 'equipment_name', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'factory_brand', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'brand_type', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'no_of_tires', keywordType: KeywordType.Numeric, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'no_of_spare_tires',
            keywordType: KeywordType.Numeric,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          { name: 'reg_number', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'condition', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'equipment_trade',
            keywordType: KeywordType.String,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          { name: 'driver', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'uid_rfid', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: false },
          { name: 'uid_nfc', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: false }
        );
        return fields;
      })(),
    },
    {
      resourceUrl: '/master/purchasing',
      fields: (() => {
        const fields = cloneDeep(commonLegacy);
        fields.splice(
          1,
          0,
          { name: 'document_id', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'invoice_date', keywordType: KeywordType.Datetime, searchable: true, fav: true },
          { name: 'invoice_number', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'supplier_id', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'unmanaged_supplier',
            keywordType: KeywordType.String,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          { name: 'trade', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'origin', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'reason_id', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'target', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'specific_equipment_id',
            keywordType: KeywordType.String,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          {
            name: 'specific_department',
            keywordType: KeywordType.String,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          { name: 'po_document_id', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true }
        );
        return fields;
      })(),
    },
    {
      resourceUrl: '/master/purchasing-detail',
      fields: (() => {
        const fields = cloneDeep(commonLegacy);
        fields.splice(
          1,
          0,
          { name: 'document_id', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'purchase_amount',
            keywordType: KeywordType.Numeric,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          {
            name: 'purchase_metric',
            keywordType: KeywordType.String,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          { name: 'component_id', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'item_name', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'maintenance_point_id',
            keywordType: KeywordType.String,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          {
            name: 'price_per_unit',
            keywordType: KeywordType.Numeric,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          { name: 'total_price', keywordType: KeywordType.Numeric, searchable: true, guidedKeyword: true, fav: true }
        );
        return fields;
      })(),
    },
    //#endregion

    //#region Tire Module
    {
      resourceUrl: '/tire/tire',
      fields: (() => {
        const fields = cloneDeep(common);
        fields.splice(
          1,
          0,
          { name: 'doc_no', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'uid_rfid', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'uid_nfc', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'serial_no', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'mnf_date', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'status',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'New', value: 'NEW' },
              { display: 'Pre Installed', value: 'PAL' },
              { display: 'Installed', value: 'TAL' },
              { display: 'Used', value: 'USE' },
              { display: 'Retreading', value: 'RTN' },
              { display: 'Retread Completed', value: 'RTC' },
              { display: 'Discarded', value: 'DSC' },
              { display: 'Omitted', value: 'OMT' },
            ],
            fav: true,
          },
          {
            name: 'category',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'Original', value: 'ORG' },
              { display: 'Retread', value: 'RTD' },
              { display: 'Retread Ex Jasa', value: 'RTX' },
            ],
            fav: true,
          },
          { name: 'rtx_gen', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'brand',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'Bridgestone', value: 'BS' },
              { display: 'Dunlop', value: 'DL' },
              { display: 'Gajah Tunggal', value: 'GT' },
              { display: 'Goodyear', value: 'GY' },
              { display: 'Swallow', value: 'SW' },
              { display: 'Maxxis', value: 'MX' },
              { display: 'MRF', value: 'MR' },
              { display: 'Kunlun', value: 'KL' },
              { display: 'Chengshan', value: 'CS' },
              { display: 'Uncommon', value: 'UC' },
            ],
            fav: true,
          },
          { name: 'size', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'remark', keywordType: KeywordType.String, searchable: true, fav: true }
        );
        return fields;
      })(),
    },
    {
      resourceUrl: '/tire/tire-status-log',
      fields: (() => {
        const fields = cloneDeep(common);
        fields.splice(
          1,
          0,
          {
            name: 'from',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'New', value: 'NEW' },
              { display: 'Pre Installed', value: 'PAL' },
              { display: 'Installed', value: 'TAL' },
              { display: 'Used', value: 'USE' },
              { display: 'Retreading', value: 'RTN' },
              { display: 'Retread Completed', value: 'RTC' },
              { display: 'Discarded', value: 'DSC' },
              { display: 'Omitted', value: 'OMT' },
            ],
          },
          {
            name: 'to',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'New', value: 'NEW' },
              { display: 'Pre Installed', value: 'PAL' },
              { display: 'Installed', value: 'TAL' },
              { display: 'Used', value: 'USE' },
              { display: 'Retreading', value: 'RTN' },
              { display: 'Retread Completed', value: 'RTC' },
              { display: 'Discarded', value: 'DSC' },
              { display: 'Omitted', value: 'OMT' },
            ],
            fav: true,
          },
          { name: 'remark', keywordType: KeywordType.String, searchable: true },
          { name: 'tag', keywordType: KeywordType.String, searchable: false, fav: true },
          { name: 'event_at', keywordType: KeywordType.Datetime, searchable: true, fav: true },
          {
            name: 'valid',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'TRUE', value: true },
              { display: 'FALSE', value: false },
            ],
            fav: true,
          },
          {
            name: 'current',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'TRUE', value: true },
              { display: 'FALSE', value: false },
            ],
            fav: true,
          }
        );
        return fields;
      })(),
    },
    {
      resourceUrl: '/tire/movement',
      fields: (() => {
        const fields = cloneDeep(common);
        fields.splice(
          1,
          0,
          { name: 'doc_no', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'status',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'Published', value: 'PUB' },
              { display: 'In Progress', value: 'IPG' },
              { display: 'Paired', value: 'PAR' },
              { display: 'Canceled', value: 'CAN' },
            ],
            fav: true,
          },
          {
            name: 'type',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'In Misc', value: 'IMIS' },
              { display: 'In USE', value: 'IUSE' },
              { display: 'Out Retreading', value: 'ORTN' },
              { display: 'Out Discarded', value: 'ODSC' },
              { display: 'Purchase Misc', value: 'PMIS' },
              { display: 'Purchase Original', value: 'PORG' },
              { display: 'Purchase Retread', value: 'PRTD' },
              { display: 'Purchase Retread Ex Jasa', value: 'PRTX' },
            ],
            fav: true,
          },
          { name: 'party', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          { name: 'ref_doc', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'discard_reason',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'Retread Failed', value: 'RFA' },
              { display: 'Sold Out', value: 'SOL' },
              { display: 'Thrown Away', value: 'TRW' },
              { display: 'Lost', value: 'LOS' },
            ],
            fav: true,
          },
          { name: 'event_at', keywordType: KeywordType.Datetime, searchable: true, fav: true },
          { name: 'remark', keywordType: KeywordType.String, searchable: true, fav: true }
        );
        return fields;
      })(),
    },
    {
      resourceUrl: '/tire/movement-status-log',
      fields: (() => {
        const fields = cloneDeep(common);
        fields.splice(
          1,
          0,
          {
            name: 'from',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'Published', value: 'PUB' },
              { display: 'In Progress', value: 'IPG' },
              { display: 'Paired', value: 'PAR' },
              { display: 'Canceled', value: 'CAN' },
            ],
          },
          {
            name: 'to',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'Published', value: 'PUB' },
              { display: 'In Progress', value: 'IPG' },
              { display: 'Paired', value: 'PAR' },
              { display: 'Canceled', value: 'CAN' },
            ],
            fav: true,
          },
          { name: 'remark', keywordType: KeywordType.String, searchable: true },
          { name: 'tag', keywordType: KeywordType.String, searchable: false, fav: true },
          { name: 'event_at', keywordType: KeywordType.Datetime, searchable: true, fav: true },
          {
            name: 'valid',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'TRUE', value: true },
              { display: 'FALSE', value: false },
            ],
            fav: true,
          },
          {
            name: 'current',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'TRUE', value: true },
              { display: 'FALSE', value: false },
            ],
            fav: true,
          }
        );
        return fields;
      })(),
    },
    {
      resourceUrl: '/tire/replacement',
      fields: (() => {
        const fields = cloneDeep(common);
        fields.splice(
          1,
          0,
          { name: 'doc_no', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'status',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'In Progress', value: 'IPG' },
              { display: 'Completed', value: 'CMP' },
              { display: 'Canceled', value: 'CAN' },
            ],
            fav: true,
          },
          {
            name: 'tire_on_status',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'New', value: 'NEW' },
              { display: 'Pre Installed', value: 'PAL' },
              { display: 'Installed', value: 'TAL' },
              { display: 'Used', value: 'USE' },
              { display: 'Retreading', value: 'RTN' },
              { display: 'Retread Completed', value: 'RTC' },
              { display: 'Discarded', value: 'DSC' },
              { display: 'Omitted', value: 'OMT' },
            ],
            fav: true,
          },
          {
            name: 'reason',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'Misc Off', value: 'MOF' },
              { display: 'Halus', value: 'HLS' },
              { display: 'Meletus', value: 'MLT' },
              { display: 'Laka', value: 'LKA' },
              { display: 'Hilang', value: 'HLG' },
              { display: 'Misc On', value: 'MON' },
              { display: 'Test Drive', value: 'TES' },
              { display: 'Exclusive On', value: 'XON' },
              { display: 'Exclusive Off', value: 'XOF' },
            ],
            fav: true,
          },
          { name: 'event_at', keywordType: KeywordType.Datetime, searchable: true, fav: true },
          {
            name: 'trade',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [{ display: 'PT Jangkar Pasifik Transport', value: 'JPT' }],
            fav: true,
          },
          { name: 'pic', keywordType: KeywordType.String, searchable: true, guidedKeyword: true, fav: true },
          {
            name: 'active',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'TRUE', value: true },
              { display: 'FALSE', value: false },
            ],
            fav: true,
          },
          {
            name: 'active_duration',
            keywordType: KeywordType.Numeric,
            searchable: true,
            guidedKeyword: true,
            fav: true,
          },
          { name: 'remark', keywordType: KeywordType.String, searchable: true, fav: true }
        );
        return fields;
      })(),
    },
    {
      resourceUrl: '/tire/replacement-status-log',
      fields: (() => {
        const fields = cloneDeep(common);
        fields.splice(
          1,
          0,
          {
            name: 'from',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'In Progress', value: 'IPG' },
              { display: 'Completed', value: 'CMP' },
              { display: 'Canceled', value: 'CAN' },
            ],
          },
          {
            name: 'to',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'In Progress', value: 'IPG' },
              { display: 'Completed', value: 'CMP' },
              { display: 'Canceled', value: 'CAN' },
            ],
            fav: true,
          },
          { name: 'remark', keywordType: KeywordType.String, searchable: true },
          { name: 'tag', keywordType: KeywordType.String, searchable: false, fav: true },
          { name: 'event_at', keywordType: KeywordType.Datetime, searchable: true, fav: true },
          {
            name: 'valid',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'TRUE', value: true },
              { display: 'FALSE', value: false },
            ],
            fav: true,
          },
          {
            name: 'current',
            keywordType: KeywordType.Map,
            searchable: true,
            options: [
              { display: 'TRUE', value: true },
              { display: 'FALSE', value: false },
            ],
            fav: true,
          }
        );
        return fields;
      })(),
    },
    //#endregion
  ];
};
