import { NodeComponent, QueryPresetConfig, QueryService, WhereFieldNode, WhereHasGroupNode } from '@davidhiu/ignite-ng';

export const getWhereHasList = (): QueryPresetConfig['whereHasList'] => {
  return [
    //#region Master Module
    {
      resourceUrl: '/master/component',
      whereHases: [],
    },
    {
      resourceUrl: '/master/equipment',
      whereHases: [
        { resourceUrl: '/tire/replacement', relationName: 'replacements' },
        { resourceUrl: '/tire/tire', relationName: 'installedTires' },
      ],
    },
    {
      resourceUrl: '/master/purchasing',
      whereHases: [{ resourceUrl: '/master/purchasing-detail', relationName: 'purchasingDetails' }],
    },
    //#endregion

    //#region Tire Module
    {
      resourceUrl: '/tire/tire',
      whereHases: [
        { resourceUrl: '/master/component', relationName: 'component' },
        { resourceUrl: '/master/equipment', relationName: 'currentEquipment' },
        { resourceUrl: '/master/equipment', relationName: 'previousEquipment' },
        { resourceUrl: '/tire/tire-status-log', relationName: 'statusLogs' },
        { resourceUrl: '/tire/tire-status-log', relationName: 'currentStatusLog' },
        { resourceUrl: '/tire/movement', relationName: 'movements' },
        { resourceUrl: '/tire/replacement', relationName: 'replacementOffs' },
        { resourceUrl: '/tire/replacement', relationName: 'replacementOns' },
      ],
    },
    {
      resourceUrl: '/tire/movement',
      whereHases: [
        { resourceUrl: '/master/purchasing', relationName: 'purchasing' },
        { resourceUrl: '/master/purchasing-detail', relationName: 'purchasing.purchasingDetails' },
        { resourceUrl: '/tire/movement-status-log', relationName: 'statusLogs' },
        { resourceUrl: '/tire/movement-status-log', relationName: 'currentStatusLog' },
        { resourceUrl: '/tire/tire', relationName: 'tires' },
      ],
    },
    {
      resourceUrl: '/tire/replacement',
      whereHases: [
        { resourceUrl: '/master/equipment', relationName: 'equipment' },
        { resourceUrl: '/master/equipment', relationName: 'truckHead' },
        { resourceUrl: '/tire/tire', relationName: 'tireOff' },
        { resourceUrl: '/tire/tire', relationName: 'tireOn' },
        { resourceUrl: '/tire/replacement-status-log', relationName: 'statusLogs' },
        { resourceUrl: '/tire/replacement-status-log', relationName: 'currentStatusLog' },
      ],
    },
    //#endregion
  ];
};

export const getQueryPresetList = (
  getWhereHasNodes: (resourceUrl: string) => WhereHasGroupNode<WhereFieldNode>[]
): QueryPresetConfig['presetList'] => {
  return [
    //#region Master Module
    {
      resourceUrl: '/master/component',
      queryPresets: [
        (() => ({
          name: QueryService.QUERY_PRESET_BLANK_NAME,
          s: NodeComponent.createGroupNode('select', 'and', [], true),
          w: NodeComponent.createGroupNode('where', 'and', [], true),
          h: getWhereHasNodes('/master/component'),
          j: NodeComponent.createGroupNode('join', 'and', [], true),
          o: NodeComponent.createGroupNode('order', 'and', [], true),
        }))(),
      ],
    },
    {
      resourceUrl: '/master/equipment',
      queryPresets: [
        (() => ({
          name: QueryService.QUERY_PRESET_BLANK_NAME,
          s: NodeComponent.createGroupNode('select', 'and', [], true),
          w: NodeComponent.createGroupNode('where', 'and', [], true),
          h: getWhereHasNodes('/master/equipment'),
          j: NodeComponent.createGroupNode('join', 'and', [], true),
          o: NodeComponent.createGroupNode('order', 'and', [], true),
        }))(),
      ],
    },
    {
      resourceUrl: '/master/purchasing',
      queryPresets: [
        (() => ({
          name: QueryService.QUERY_PRESET_BLANK_NAME,
          s: NodeComponent.createGroupNode('select', 'and', [], true),
          w: NodeComponent.createGroupNode('where', 'and', [], true),
          h: getWhereHasNodes('/master/purchasing'),
          j: NodeComponent.createGroupNode('join', 'and', [], true),
          o: NodeComponent.createGroupNode('order', 'and', [], true),
        }))(),
      ],
    },
    //#endregion

    //#region Tire Module
    {
      resourceUrl: '/tire/tire',
      queryPresets: [
        (() => ({
          name: QueryService.QUERY_PRESET_BLANK_NAME,
          s: NodeComponent.createGroupNode('select', 'and', [], true),
          w: NodeComponent.createGroupNode('where', 'and', [], true),
          h: getWhereHasNodes('/tire/tire'),
          j: NodeComponent.createGroupNode('join', 'and', [], true),
          o: NodeComponent.createGroupNode('order', 'and', [], true),
        }))(),
        (() => {
          const qAdv = {
            name: 'Status Log',
            s: NodeComponent.createGroupNode('select', 'and', [], true),
            w: NodeComponent.createGroupNode('where', 'and', [], true),
            h: getWhereHasNodes('/tire/tire'),
            j: NodeComponent.createGroupNode('join', 'and', [], true),
            o: NodeComponent.createGroupNode('order', 'and', [], true),
          };
          qAdv.h
            .find((h) => h.r === 'statusLogs')
            ?.g.push(
              ...[
                NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
              ]
            );
          return qAdv;
        })(),
      ],
    },
    {
      resourceUrl: '/tire/movement',
      queryPresets: [
        (() => ({
          name: QueryService.QUERY_PRESET_BLANK_NAME,
          s: NodeComponent.createGroupNode('select', 'and', [], true),
          w: NodeComponent.createGroupNode('where', 'and', [], true),
          h: getWhereHasNodes('/tire/movement'),
          j: NodeComponent.createGroupNode('join', 'and', [], true),
          o: NodeComponent.createGroupNode('order', 'and', [], true),
        }))(),
        (() => {
          const qAdv = {
            name: 'Status Log',
            s: NodeComponent.createGroupNode('select', 'and', [], true),
            w: NodeComponent.createGroupNode('where', 'and', [], true),
            h: getWhereHasNodes('/tire/movement'),
            j: NodeComponent.createGroupNode('join', 'and', [], true),
            o: NodeComponent.createGroupNode('order', 'and', [], true),
          };
          qAdv.h
            .find((h) => h.r === 'statusLogs')
            ?.g.push(
              ...[
                NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
              ]
            );
          return qAdv;
        })(),
      ],
    },
    {
      resourceUrl: '/tire/replacement',
      queryPresets: [
        (() => ({
          name: QueryService.QUERY_PRESET_BLANK_NAME,
          s: NodeComponent.createGroupNode('select', 'and', [], true),
          w: NodeComponent.createGroupNode('where', 'and', [], true),
          h: getWhereHasNodes('/tire/replacement'),
          j: NodeComponent.createGroupNode('join', 'and', [], true),
          o: NodeComponent.createGroupNode('order', 'and', [], true),
        }))(),
        (() => {
          const qAdv = {
            name: 'Status Log',
            s: NodeComponent.createGroupNode('select', 'and', [], true),
            w: NodeComponent.createGroupNode('where', 'and', [], true),
            h: getWhereHasNodes('/tire/replacement'),
            j: NodeComponent.createGroupNode('join', 'and', [], true),
            o: NodeComponent.createGroupNode('order', 'and', [], true),
          };
          qAdv.h
            .find((h) => h.r === 'statusLogs')
            ?.g.push(
              ...[
                NodeComponent.createWhereFieldNode('and', 'to', '=', ''),
                NodeComponent.createWhereFieldNode('and', 'event_at', 'between', ''),
                NodeComponent.createWhereFieldNode('and', 'valid', '=', 'TRUE'),
              ]
            );
          return qAdv;
        })(),
      ],
    },
    //#endregion
  ];
};
